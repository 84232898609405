import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '../../components/SEO';
import Layout from '../../components/layout';
import GetServiceItems from '../../components/getServiceItems';
import bg from '../../images/bg_image_trainings_page.jpg';
import logo from '../../images/Logo@2x.png';

export default props => {
  const { data, location } = props;
  const courseList = data.allPagecontentJson;
  const allCourses = data.allCoursesJson;

  return (
    <>
      <SEO
        title="Konsultointi"
        description="Tutustu prosessien automatisointi -palveluihimme. Prosessien automatisointi tuo säästöjä, kun työaikaa vapautuu merkityksellisten asioiden tekemiseen. Meiltä saat apua prosessien automatisointiin ketterästi ja edullisesti."
      />
      <Layout
        mainTitle={'Prosessien automatisointia'}
        mainTitleClasses={'mt-3'}
        additionalTitle={'Työelämän tarpeisiin'}
        headerBgStyles={{ background: 'url(' + bg + ')' }}
        headerTitleStyle={{ color: '#0086ef' }}
        headerAdditionalTitleStyle={{ color: '#019999' }}
        additionalTitleClassNames={'mb-3 mt-md-0 mb-md-3'}
        headerNavLinkStyle={{ color: '#7a7c81' }}
        logo={logo}
        {...props}
      >
        <div className="services">
          {/* {courseList.nodes[0].service_list.map(
            ({ scroll_to_id, service_group_title, items }, index) => (
              <div
                className="service-group-wrapper"
                key={getKeyFromString(service_group_title)}
                id={scroll_to_id}
              >
                <div className="service-group text-center">
                  <h2 className="service-group-title">{service_group_title}</h2>
                </div>
                {getServiceItems(items, allCourses)}
              </div>
            )
          )} */}
          <GetServiceItems
            items={courseList.nodes[0].service_list[2].items}
            allCourses={allCourses}
            extra={{ title: 'Jätä yhteydenottopyyntö' }}
          />
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query MyQuery3 {
    allPagecontentJson {
      nodes {
        service_list {
          items {
            cta
            description
            id
            title
          }
          service_group_title
          service_group_id
          scroll_to_id
        }
        info_at_top {
          topics {
            link
            title
          }
        }
      }
    }
    allCoursesJson {
      edges {
        node {
          id
          url_path
        }
      }
    }
  }
`;
